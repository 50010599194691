import { put, takeEvery, select, call, type StrictEffect, type SagaReturnType } from 'redux-saga/effects'
import { getVehiclesInZone, getZoneById, getVehicleModels, type GetVehicleParams, getAllOrganizations } from 'src/api'
import {
    setVehicles,
    setMapLoading,
    setInitialVmapFilters,
    setCurrentZoneVmap,
    setAllVmapModels,
} from 'src/redux/vmap/vmap.actions'
import { selectAllVmapModels, selectVmapFilters } from 'src/redux/vmap/vmap.selectors'
import {
    RESET_VMAP_STATES,
    TOGGLE_VMAP_ALL_STATES,
    TOGGLE_VMAP_STATE_CHILD,
    TOGGLE_VMAP_STATE_PARENT,
    UPDATE_VEHICLE_MAP,
    ADD_ZONE_FILTER_VMAP,
    GET_INITIAL_VMAP_FILTERS,
    ADD_BATTERY_FILTER_VMAP,
    REMOVE_BATTERY_FILTER_VMAP,
    REMOVE_ZONE_FILTER_VMAP,
    TOGGLE_VMAP_MODEL_FILTER,
    TOGGLE_VMAP_ALL_MODEL_FILTERS,
    RESET_VMAP_MODEL_FILTERS,
    TOGGLE_TASK_PRIORITY_FILTER,
    TOGGLE_TASK_PRIORITY,
    type AddZoneFilter,
    SET_ORGANIZATION_VMAP_FILTER,
} from 'src/redux/vmap/vmap.types'
import { getAllActiveStates } from 'src/components/app/utils/vmap/vmapUtils'
import { requestFetchAreas } from 'src/redux/zoneAreas'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import { type Vehicle, type VehicleModel } from 'src/api/fm/vehicles/vehicles.model'
import { type VmapFilters } from 'src/components/vmap/filters/types'
import { selectOrganizations } from 'src/redux/hunter'
import type { Organization } from 'src/redux/organization/organization.types'

// If we need to remove or add a new filter key (like adding a new vehicle status), the version should be updated
const LOCAL_STORAGE_KEY = 'vmap_filters_v12'

type GetZoneByIdRes = SagaReturnType<typeof getZoneById>

export function* getAreasInZone(zoneId: string): any {
    try {
        yield put(requestFetchAreas(zoneId))

        const zone: GetZoneByIdRes = yield call(getZoneById, zoneId)
        if (zone instanceof Error) {
            throw zone
        }
        const currentZone = {
            id: zoneId,
            boundaries: zone.polygon,
            isSleeping: zone.isSleeping,
        }
        yield put(setCurrentZoneVmap(currentZone))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type GetVehiclesInZoneRes = SagaReturnType<typeof getVehiclesInZone>

export function* addZoneFilter(action: AddZoneFilter): any {
    try {
        const { battery }: VmapFilters = yield select(selectVmapFilters)

        const zoneId = action.payload.value.toString()
        yield getAreasInZone(zoneId)

        const filterParams: GetVehicleParams = {
            zone: zoneId,
            ...battery,
        }
        const vehicles: GetVehiclesInZoneRes = yield call(getVehiclesInZone, filterParams)

        if (vehicles instanceof Error) {
            throw vehicles
        }
        yield put(setVehicles(vehicles))
    } catch (e) {
        console.log('Failed to add zone filter', e)
    }
}

export function* updateMap(): any {
    try {
        const { city, battery, status, models, organization }: VmapFilters = yield select(selectVmapFilters)

        if (city) {
            const activeStates = getAllActiveStates(status).join(',')
            const availability_status = activeStates.length ? activeStates : null

            if (activeStates.length && models.length) {
                yield put(setMapLoading(true))

                const allModels = yield select(selectAllVmapModels)
                const model_ids = models.length === allModels.length ? null : models.join(',')

                const filterParams: GetVehicleParams = {
                    zone: city.value,
                    ...battery,
                    availability_status,
                    model_ids,
                    organization_id: organization,
                }
                const newVehicles: Vehicle[] | Error = yield call(getVehiclesInZone, filterParams)

                if (newVehicles instanceof Error) {
                    throw newVehicles
                }

                yield put(setVehicles(newVehicles))
            }
        }
    } catch (e) {
    } finally {
        yield put(setMapLoading(false))
    }
}

function* getInitialFilters(): any {
    try {
        const storedFilters = yield localStorage.getItem(LOCAL_STORAGE_KEY)
        const filters: VmapFilters = JSON.parse(storedFilters) || {}

        filters.models = yield getInitialModelFilters(filters.models)
        filters.organization = yield getInitialOrganizationFilter(filters.organization)

        yield put(setInitialVmapFilters(filters))

        if (filters.city) {
            yield getAreasInZone(filters.city.value)
        }
        yield updateMap()
    } catch (e) {
        console.log('Failed to get vehicle map filters from localstorage', e)
    }
}

function* addFilterLocalStorage(): any {
    try {
        const currentFilters = yield select(selectVmapFilters)
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(currentFilters))
    } catch (e) {
        console.log('Failed to save filter in local storage', e)
    }
}

export default function* watcher(): any {
    yield takeEvery(ADD_ZONE_FILTER_VMAP, addZoneFilter)
    yield takeEvery(
        [
            UPDATE_VEHICLE_MAP,
            ADD_BATTERY_FILTER_VMAP,
            TOGGLE_VMAP_STATE_PARENT,
            TOGGLE_TASK_PRIORITY_FILTER,
            TOGGLE_TASK_PRIORITY,
        ],
        updateMap,
    )
    yield takeEvery(GET_INITIAL_VMAP_FILTERS, getInitialFilters)
    yield takeEvery(
        [
            ADD_ZONE_FILTER_VMAP,
            ADD_BATTERY_FILTER_VMAP,
            REMOVE_BATTERY_FILTER_VMAP,
            REMOVE_ZONE_FILTER_VMAP,
            TOGGLE_VMAP_ALL_STATES,
            TOGGLE_VMAP_STATE_PARENT,
            TOGGLE_VMAP_STATE_CHILD,
            RESET_VMAP_STATES,
            TOGGLE_VMAP_MODEL_FILTER,
            TOGGLE_VMAP_ALL_MODEL_FILTERS,
            RESET_VMAP_MODEL_FILTERS,
            TOGGLE_TASK_PRIORITY_FILTER,
            TOGGLE_TASK_PRIORITY,
            SET_ORGANIZATION_VMAP_FILTER,
        ],
        addFilterLocalStorage,
    )
}

function* getInitialModelFilters(storedModelFilters: string[]): Generator<StrictEffect, string[], VehicleModel[]> {
    const allModels = yield call(getVehicleModels)

    if (allModels instanceof Error) {
        throw allModels
    }

    yield put(setAllVmapModels(allModels.map(vm => ({ id: vm.id, category: vm.category, name: vm.name }))))

    let initialModelFilters: string[]

    if (storedModelFilters?.length > 0) {
        // Remove model filters that are obsolete (i.e. have been deleted from our systems)
        const nonObsoleteModelFilters = storedModelFilters.filter(vm => allModels.some(vmr => vmr.id === vm))

        if (nonObsoleteModelFilters.length > 0) {
            initialModelFilters = nonObsoleteModelFilters
        } else {
            initialModelFilters = allModels.map(vm => vm.id)
        }
    } else {
        initialModelFilters = allModels.map(vm => vm.id)
    }

    return initialModelFilters
}

function* getInitialOrganizationFilter(
    storedOrganizationFilter: string | undefined,
): Generator<StrictEffect, string | undefined, Organization[]> {
    if (!storedOrganizationFilter) {
        return undefined
    }

    let allOrganizations = yield select(selectOrganizations)

    // If the initial page load is for the vehicle map there is a potential race condition where the GET organizations API call haven't finished yet
    if (allOrganizations.length === 0) {
        allOrganizations = yield call(getAllOrganizations)
    }

    // Verify that the stored organization still exists (i.e. has not been deleted from our systems)
    if (allOrganizations.some(organization => organization.id === storedOrganizationFilter)) {
        return storedOrganizationFilter
    }

    return undefined
}
