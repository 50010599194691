import { type ExecutionPriority, type TaskStatus, type TaskType } from 'src/api/fm/tasks/tasks.model'
import { type VehicleStatus } from 'src/api/fm/vehicles/vehicles.model'

// Only supported for certain task types (e.g. 'in_field_quality_check', 'transport')
export type CreateTaskVehicleStatus = Extract<VehicleStatus, 'bounty' | 'ready'>

export interface TaskFormProps {
    type?: TaskType
    email?: string
    vehicleShort?: string
    infoInternal?: string
    info?: string
    executionPriority?: ExecutionPriority
    riderIncentivized?: boolean
    vehicleStatus?: CreateTaskVehicleStatus
}

export interface NewTaskReq extends TaskFormProps {
    vehicleId: string
}

export const INITIAL_TASK: TaskFormProps = {
    email: '',
    vehicleShort: '',
    infoInternal: '',
    info: '',
    executionPriority: 'normal',
    riderIncentivized: false,
}

export const TASK_DESCRIPTION_MAX_LENGTH = 48

export const TASK_STATUS_OPTIONS: Record<Extract<TaskStatus, 'new' | 'pending' | 'fulfilled' | 'declined'>, string> = {
    new: 'New',
    pending: 'Pending',
    fulfilled: 'Fulfilled',
    declined: 'Declined',
}
export const TASK_TYPE_OPTIONS: Record<TaskType, string> = {
    transport: 'Transport',
    battery_swap: 'Battery Swap',
    rebalance: 'Rebalance',
    repark: 'Repark',
    deploy: 'Deploy',
    rescue: 'Rescue',
    storage: 'Storage',
    in_field_quality_check: 'Quality Check',
    in_field_repair: 'In Field Repair',
    license_plate_swap: 'License Plate Swap',
}
// eslint-disable-next-line import/no-unused-modules
export const { in_field_repair, ...TASK_TYPE_OPTIONS_FOR_CREATE_NEW_TASK } = TASK_TYPE_OPTIONS

export const requireDescriptionTasks = ['transport', 'rebalance']

export const noAssigneeTasks = ['storage', 'rescue', 'repark', 'in_field_quality_check']
