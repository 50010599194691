import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError, isNotFoundError } from 'src/api/utils'
import {
    DriversLicenseDocumentStatus,
    DriversLicenseFaceCheckStatus,
    type AccountSharingInfo,
    type DriversLicenseStatus,
    type GetDriversLicenseStatusResponse,
    type GetRiderResponse,
    type Rider,
} from 'src/api/fm/users/users.model'
import { type AxiosResponse } from 'axios'
import { type Roles } from 'src/redux/permissions/permissions.types'

export const updateUserRoles = async (id: string, data: Roles): Promise<void | Error> => {
    const link = encodeURI(`/v1/users/admin/user/${id}/roles`)
    const headers = await getHeaders()
    const payload = { roles: data.global }

    const result = await ins
        .patch(link, payload, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
    return result
}

export const searchRider = async (searchTerm: string) => {
    const link = encodeURI(`/v1/fm/users/search/${searchTerm}`)
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const getRider = async (searchTerm: string): Promise<Rider | Error> => {
    const link = encodeURI(`/v1/fm/user/${searchTerm}`)
    const headers = await getHeaders()
    return ins
        .get<GetRiderResponse>(link, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const getAccountSharingInfo = async (userId: string): Promise<AccountSharingInfo | Error> => {
    const link = encodeURI(`/v1/fm/user/${userId}/journal`)
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const deleteUser = async (id: string, reason: string): Promise<void | Error> => {
    const link = encodeURI('/v1/users/admin/fullprofile/' + id)
    const headers = await getHeaders()
    return ins
        .delete(link, { data: { reason: reason }, headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const giveRiderCredit = async (userId: string, amount: number): Promise<void | Error> => {
    const link = '/v1/fm/payments/users/' + userId + '/give/' + amount + '/voi'
    const headers = await getHeaders()

    return ins
        .put(link, {}, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const giveRiderDiscount = async (
    riderId: string,
    name: string,
    // description: string,
    internalName: string,
    expiresAt: number,
    amount: number,
    credits: number,
    unlockFeeDiscountPercent: number,
    rideFeeDiscountPercent: number,
    rideFeeDiscountMinutes: number,
): Promise<void | Error> => {
    const link = 'v1/fm/users/' + riderId + '/uso-discounts'
    const headers = await getHeaders()

    const postBody = {
        name,
        // description,
        internalName,
        expiresAt,
        amount,
        credits,
        unlockFeeDiscountPercent,
        rideFeeDiscountPercent,
        rideFeeDiscountMinutes,
    }

    return ins
        .post(link, postBody, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export async function forgiveUserDebt(userId: string) {
    const link = encodeURI(`v1/fm/payments/users/${userId}/forgive-all-debts`)
    const headers = await getHeaders()

    return ins.post(link, {}, { headers })
}

export const blockUser = async (userId: string, reason: string, type: string): Promise<void | Error> => {
    const link = encodeURI('v1/users/admin/block')
    const postBody = {
        userId,
        reason,
        type,
    }
    const headers = await getHeaders()

    return ins
        .post(link, postBody, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const unblockUser = async (userId: string, type: string): Promise<void | Error> => {
    const postBody = {
        userId,
        type,
    }
    const link = encodeURI('v1/users/admin/unblock')
    const headers = await getHeaders()

    return ins
        .post(link, postBody, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

// TODO: These will fully replace blockUser / unblockUser methods when the backend will refactor their side
export const blockUserFM = async (userId: string, reason: string, type: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/user/${userId}/block`)
    const postBody = { reason, type }
    const headers = await getHeaders()

    return ins
        .post(link, postBody, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const unblockUserFM = async (userId: string, type: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/user/${userId}/unblock`)
    const postBody = { type }
    const headers = await getHeaders()

    return ins
        .post(link, postBody, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/user%20driving%20licence/getDrivingLicence API documentation}
 */
export const getDriversLicenseStatus = async (riderId: string) => {
    const link = encodeURI(`/v1/fm/users/${riderId}/driving-licence`)
    const headers = await getHeaders()
    return ins
        .get<GetDriversLicenseStatusResponse>(link, { headers })
        .then(res => mapGetDriversLicenseStatus(res.data))
        .catch(e => {
            if (!isNotFoundError(e)) {
                return getError(e, 'response.data.errors[0]')
            }

            return undefined
        })
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/user%20driving%20licence/deleteDrivingLicence API documentation}
 */
export const deleteDriversLicense = async (riderId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/users/${riderId}/driving-licence`)
    const headers = await getHeaders()
    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/user%20driving%20licence/updateDocumentStatus API documentation}
 */
export const approveDriversLicenseDocument = async (riderId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/users/${riderId}/driving-licence/document-status`)
    const headers = await getHeaders()
    const body = {
        status: DriversLicenseDocumentStatus.Ok,
    }
    return ins
        .post(link, body, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/user%20driving%20licence/updateFaceCheckStatus API documentation}
 */
export const approveDriversLicenseFaceCheck = async (riderId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/users/${riderId}/driving-licence/face-check-status`)
    const headers = await getHeaders()
    const body = {
        status: DriversLicenseFaceCheckStatus.Ok,
    }
    return ins
        .post(link, body, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/user%20document/updateUserDocument API documentation}
 */
export const updateDateOfBirth = async (riderId: string, dateOfBirth: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/users/${riderId}/document`)
    const headers = await getHeaders()
    const body = {
        date_of_birth: dateOfBirth,
    }
    return ins
        .patch(link, body, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetDriversLicenseStatus = (response: GetDriversLicenseStatusResponse): DriversLicenseStatus => ({
    documentType: response.data.documentType,
    documentStatus: response.data.documentStatus,
    documentUsage: response.data.documentUsage,
    faceCheckStatus: response.data.faceCheckStatus,
    identityProviderLink: response.data.identityProviderLink,
    identityProviderName: response.data.identityProviderName,
})

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/fm-api/definition#/user%20device/createDeviceSwitchUnblock API documentation}
 */
export const unblockDeviceSwitch = async (id: string, reason: string): Promise<AxiosResponse<any> | Error> => {
    const link = encodeURI(`/v1/fm/users/${id}/device-switch-unblocks`)
    const headers = await getHeaders()
    const body = {
        reason,
    }
    return ins.post(link, body, { headers }).catch(e => getError(e, 'response.data.errors[0]'))
}

export const requestUserDocument = async (reason: string, userIds: string[]): Promise<void | Error> => {
    const link = encodeURI('v1/fm/users/identity-check/request')
    const headers = await getHeaders()
    const postBody = {
        reason,
        userIds,
    }

    return ins
        .post(link, postBody, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
