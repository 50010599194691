import moment, { type Duration } from 'moment'
import { unixToMoment } from 'src/utils/time/timeUtils'
import {
    PARKING_ASSISTANT_PARKING_QUALITY,
    PARKING_ASSISTANT_PHOTO_QUALITY,
    ParkingPhotoDecision,
    type Ride,
} from 'src/api/fm/rentals/rentals.model'
import {
    DriversLicenseDocumentStatus,
    DriversLicenseFaceCheckStatus,
    IdentificationDocumentType,
} from 'src/api/fm/users/users.model'
import { StatusIndicatorColor } from 'src/ui-kit/statusIndicator/StatusIndicator'

export const getRideDuration = (startTime: number): Duration => {
    return moment.duration(moment().diff(unixToMoment(startTime)))
}

export const getFormattedRideDuration = (startTime: number) => {
    const durationAsMinutes = getRideDurationInMinutes(startTime)
    const minutes = durationAsMinutes % 60
    const hours = (durationAsMinutes - minutes) / 60

    return `${hours}h ${minutes}m`
}

export function getRideDurationInMinutes(startTime: number) {
    return moment().diff(unixToMoment(startTime), 'minutes')
}

export const getSuggestedCost = (ride: Ride) => {
    if (ride.first_lock_attempt) {
        return unixToMoment(ride.first_lock_attempt).diff(unixToMoment(ride.start_time), 'minutes')
    }
    return 0
}

interface FormattingWithLabel {
    label: string
}

interface IdentificationDocumentFormatting extends FormattingWithLabel {}

interface DriversLicenseItemStatusFormatting extends FormattingWithLabel {
    statusIndicator: StatusIndicatorColor
}

export const getDriversLicenseDocumentDetails = (
    status: DriversLicenseDocumentStatus | undefined,
): DriversLicenseItemStatusFormatting | null => {
    switch (status) {
        case DriversLicenseDocumentStatus.Ok:
            return {
                label: 'Ok',
                statusIndicator: StatusIndicatorColor.SUCCESS,
            }
        case DriversLicenseDocumentStatus.Processing:
            return {
                label: 'Processing',
                statusIndicator: StatusIndicatorColor.WARNING,
            }

        case DriversLicenseDocumentStatus.Blocked:
            return {
                label: 'Blocked',
                statusIndicator: StatusIndicatorColor.ERROR,
            }
        case DriversLicenseDocumentStatus.Duplicated:
            return {
                label: 'Duplicated',
                statusIndicator: StatusIndicatorColor.ERROR,
            }
        case DriversLicenseDocumentStatus.Expired:
            return {
                label: 'Expired',
                statusIndicator: StatusIndicatorColor.ERROR,
            }
        case DriversLicenseDocumentStatus.FaceNotDetected:
            return {
                label: 'Face not detected',
                statusIndicator: StatusIndicatorColor.ERROR,
            }
        case DriversLicenseDocumentStatus.MissingData:
            return {
                label: 'Missing data',
                statusIndicator: StatusIndicatorColor.ERROR,
            }
        case DriversLicenseDocumentStatus.Rejected:
            return {
                label: 'Rejected',
                statusIndicator: StatusIndicatorColor.ERROR,
            }
        case DriversLicenseDocumentStatus.Suspected:
            return {
                label: 'Suspected',
                statusIndicator: StatusIndicatorColor.ERROR,
            }
        case DriversLicenseDocumentStatus.UnsupportedType:
            return {
                label: 'Unsupported type',
                statusIndicator: StatusIndicatorColor.ERROR,
            }
        case DriversLicenseDocumentStatus.Requested:
            return {
                label: 'Requested',
                statusIndicator: StatusIndicatorColor.ERROR,
            }
        case DriversLicenseDocumentStatus.Unknown:
        default:
            return null
    }
}

export const getDriversLicenseFaceCheckDetails = (
    status: DriversLicenseFaceCheckStatus | undefined,
): DriversLicenseItemStatusFormatting | null => {
    switch (status) {
        case DriversLicenseFaceCheckStatus.Ok:
            return {
                label: 'Ok',
                statusIndicator: StatusIndicatorColor.SUCCESS,
            }
        case DriversLicenseFaceCheckStatus.Processing:
            return {
                label: 'Processing',
                statusIndicator: StatusIndicatorColor.WARNING,
            }

        case DriversLicenseFaceCheckStatus.Rejected:
            return {
                label: 'Rejected',
                statusIndicator: StatusIndicatorColor.ERROR,
            }
        case DriversLicenseFaceCheckStatus.VerificationNeeded:
            return {
                label: 'Verification needed',
                statusIndicator: StatusIndicatorColor.WARNING,
            }

        case DriversLicenseFaceCheckStatus.Unknown:
        default:
            return null
    }
}

export const getIdentificationDocumentTypeDetails = (
    status: IdentificationDocumentType,
): IdentificationDocumentFormatting | null => {
    switch (status) {
        case IdentificationDocumentType.Generic:
            return { label: 'Generic' }
        case IdentificationDocumentType.DrivingLicense:
            return { label: 'Driving License' }
        case IdentificationDocumentType.NationalID:
            return { label: 'National ID' }
        case IdentificationDocumentType.Passport:
            return { label: 'Passport' }
        case IdentificationDocumentType.ResidencePermit:
            return { label: 'Residence Permit' }
        case IdentificationDocumentType.Visa:
            return { label: 'Visa' }
        case IdentificationDocumentType.WorkPermit:
            return { label: 'Work Permit' }
        case IdentificationDocumentType.SwedishBankId:
            return { label: 'Bank ID (Sweden)' }
        case IdentificationDocumentType.NorwegianBankId:
            return { label: 'Bank ID (Norway)' }
        case IdentificationDocumentType.Vipps:
            return { label: 'Vipps' }
        case IdentificationDocumentType.SPID:
            return { label: 'SPID' }
        case IdentificationDocumentType.Itsme:
            return { label: 'itsme®' }
        default:
            return null
    }
}

export const getDecisionDescription = (decision: ParkingPhotoDecision | undefined) => {
    switch (decision) {
        case ParkingPhotoDecision.GOOD_PARKING:
            return 'Good Parking'
        case ParkingPhotoDecision.UNCLEAR_PARKING:
            return 'Unclear Parking'
        case ParkingPhotoDecision.NOT_IDEAL_PARKING:
            return 'Not Ideal Parking (Info)'

        case ParkingPhotoDecision.BAD_PHOTO_TOO_DARK:
            return 'Bad Photo (Dark)'
        case ParkingPhotoDecision.BAD_PHOTO_NOTIFY:
            return 'Bad Photo (Info)'
        case ParkingPhotoDecision.BAD_PHOTO_WARN:
            return 'Bad Photo (Warning)'
        case ParkingPhotoDecision.BAD_PHOTO_FINE:
            return 'Bad Photo (Fine)'

        case ParkingPhotoDecision.BAD_PARKING_WARN:
            return 'Bad Parking (Warning)'
        case ParkingPhotoDecision.BAD_PARKING_FINE:
            return 'Bad Parking (Fine)'

        default:
            return null
    }
}

export const getParkingAssistantDescription = (
    decision: PARKING_ASSISTANT_PARKING_QUALITY | PARKING_ASSISTANT_PHOTO_QUALITY,
) => {
    switch (decision) {
        case PARKING_ASSISTANT_PHOTO_QUALITY.PHOTO_GOOD:
        case PARKING_ASSISTANT_PARKING_QUALITY.PARKING_GOOD:
            return 'Good'
        case PARKING_ASSISTANT_PARKING_QUALITY.PARKING_BAD:
        case PARKING_ASSISTANT_PHOTO_QUALITY.PHOTO_BAD:
            return 'Bad'

        case PARKING_ASSISTANT_PHOTO_QUALITY.PHOTO_DARK:
            return 'Dark'

        case PARKING_ASSISTANT_PARKING_QUALITY.PARKING_NOT_IDEAL:
            return 'Not ideal'

        case PARKING_ASSISTANT_PHOTO_QUALITY.PHOTO_UNKNOWN:
            return 'Unknown'

        default:
            return null
    }
}
