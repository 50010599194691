import { put, takeEvery, select, call, type SagaReturnType } from 'redux-saga/effects'
import {
    getScooterHistoryTasks,
    updateVehicle,
    getVehicle,
    removeFromRepair as removeFromRepairRequest,
    bikeUnarmLock as bikeUnarmLockRequest,
    getRider,
    getActiveRideVehicle,
    getRideHistoryVehicle,
    getLicensePlatesBulk,
    decommissionVehicles,
    getVehicleDecommissionedInfo,
    undoDecommissionVehicles,
} from 'src/api'
import {
    setCurrentVehicle,
    setRideHistory,
    setVehicleTaskHistory,
    setCurrentVehicleRider,
    setEndOfTaskHistoryPagination,
    incrementTaskHistoryPagination,
    getVehicle as getVehicleAction,
    getVehicleError,
    getVehicleLicensePlateSuccess,
    selectCurrentScooter,
    selectScooterRideHistory,
    selectScooterRideHistoryDateFilter,
    selectScooterRideHistorySourceFilter,
    selectVehicleTaskHistory,
    selectVehicleTaskHistoryEndOfPagination,
    selectVehicleTaskHistoryPageNum,
    type GetVehicleAction,
    setVehicleDecommissionedInfo,
    setIsFetchingVehicleTaskHistory,
} from 'src/redux/scooter'
import { appendTaskIdsWithParkingPhoto, setIsFetchingMoreTasksDone } from 'src/redux/hunterParkingPhotos'
import {
    UPDATE_VEHICLE_INFO,
    REMOVE_VEHICLE_FROM_REPAIR,
    GET_VEHICLE_TASK_HISTORY,
    GET_VEHICLE_TASK_HISTORY_NEXT_PAGE,
    INCREMENT_TASK_HISTORY_PAGINATION,
    GET_VEHICLE_RIDE_HISTORY,
    GET_VEHICLE,
    BIKE_UNARM_LOCK,
    DECOMMISSION_VEHICLE,
    GET_VEHICLE_DECOMMISSIONED_INFO,
    UNDO_DECOMMISSION_VEHICLE,
} from 'src/redux/scooter/scooter.types'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import { type LicensePlate } from 'src/models/licensePlate'
import {
    type DecommissionVehicleResult,
    type UndoDecommissionVehicleResult,
} from 'src/api/fm/decommission/decommission.model'

type GetVehicleRes = SagaReturnType<typeof getVehicle>
type GetActiveRideRes = SagaReturnType<typeof getActiveRideVehicle>
type GetActiveRiderRes = SagaReturnType<typeof getRider>

// Call this on page mount, gets vehicle by id/short/vin/imei
// the setCurrentVehicle to the response
export function* getVehicleData(action: GetVehicleAction<typeof GET_VEHICLE>) {
    const id = action.payload

    try {
        const vehicleRes: GetVehicleRes = yield call(getVehicle, id)

        if (vehicleRes instanceof Error) {
            throw vehicleRes
        }

        const activeRide: GetActiveRideRes = yield call(getActiveRideVehicle, vehicleRes.id)
        if (activeRide) {
            const ride = activeRide.data.data
            const activeRider: GetActiveRiderRes = yield call(getRider, ride.user_id)
            if (!(activeRider instanceof Error)) {
                const { userInfo } = activeRider
                yield put(
                    setCurrentVehicleRider({
                        id: userInfo.id,
                        email: userInfo.email,
                    }),
                )
            } else {
                yield put(setCurrentVehicleRider(null))
            }
        } else {
            yield put(setCurrentVehicleRider(null))
        }
        const licensePlates: LicensePlate[] | Error = yield call(getLicensePlatesBulk, [vehicleRes.id])
        const licensePlateNumber = licensePlates instanceof Error ? null : licensePlates[0]?.number
        yield put(setCurrentVehicle(vehicleRes))
        yield put(getVehicleLicensePlateSuccess(licensePlateNumber))
    } catch (e) {
        yield put(getVehicleError(`Your search "${id}" did not match any vehicles`))
    }
}

type GetVehicleDecommissionedInfoRes = SagaReturnType<typeof getVehicleDecommissionedInfo>

export function* getVehicleDecommissionedInfoSaga(action: GetVehicleAction<typeof GET_VEHICLE_DECOMMISSIONED_INFO>) {
    try {
        const { vehicleId } = action.payload
        const decommissionedInfo: GetVehicleDecommissionedInfoRes = yield call(getVehicleDecommissionedInfo, vehicleId)

        if (decommissionedInfo instanceof Error) {
            yield call(notifyUser, { message: 'Failed to get vehicle decommissioned info' }, 'error')
            return
        }

        yield put(setVehicleDecommissionedInfo(decommissionedInfo))
    } catch (e) {
        yield call(notifyUser, e)
    }
}

type GetRideHistoryRes = SagaReturnType<typeof getRideHistoryVehicle>

export function* fetchRideHistory(action: GetVehicleAction<typeof GET_VEHICLE_RIDE_HISTORY>) {
    try {
        const scooter: ReturnType<typeof selectCurrentScooter> = yield select(selectCurrentScooter)
        if (!scooter) {
            throw new Error('Could not fetch vehicle')
        }

        const date: ReturnType<typeof selectScooterRideHistoryDateFilter> = yield select(
            selectScooterRideHistoryDateFilter,
        )

        const source: ReturnType<typeof selectScooterRideHistorySourceFilter> = yield select(
            selectScooterRideHistorySourceFilter,
        )

        const params: any = {
            date: date,
            page_number: action.payload,
            page_size: 10,
            source: source,
            sort_field: 'end_time',
        }

        const history: GetRideHistoryRes = yield call(getRideHistoryVehicle, scooter.id, params)
        if (history instanceof Error) {
            throw history
        }

        let updatedHistory
        if (action.payload > 0) {
            const currentHistory: ReturnType<typeof selectScooterRideHistory> = yield select(selectScooterRideHistory)
            updatedHistory = currentHistory ? currentHistory.concat(history) : history
        } else {
            updatedHistory = history
        }
        updatedHistory.sort((a, b) => b.startTime - a.startTime)
        yield put(setRideHistory(updatedHistory))
    } catch (e) {
        console.log('Failed to get ride history', e)
    }
}

type GetVehicleTaskHistoryRes = SagaReturnType<typeof getScooterHistoryTasks>

export function* fetchTaskHistory() {
    try {
        const page: ReturnType<typeof selectVehicleTaskHistoryPageNum> = yield select(selectVehicleTaskHistoryPageNum)
        const scooter: ReturnType<typeof selectCurrentScooter> = yield select(selectCurrentScooter)
        if (!scooter) {
            throw new Error('Could not fetch vehicle')
        }

        yield put(setIsFetchingVehicleTaskHistory(true))

        const data: GetVehicleTaskHistoryRes = yield call(getScooterHistoryTasks, scooter.id, page, 10, '-created_at')
        if (data instanceof Error) {
            throw data
        }

        if (!data || !data.length) {
            yield put(setEndOfTaskHistoryPagination())
        } else {
            const currentHistory: ReturnType<typeof selectVehicleTaskHistory> = yield select(selectVehicleTaskHistory)
            const updatedHistory = currentHistory.concat(data ? data : [])
            const newTaskIdsWithPhoto = data.filter(t => t.hasPhoto).map(t => t.id)
            yield put(setVehicleTaskHistory(updatedHistory))
            yield put(appendTaskIdsWithParkingPhoto(newTaskIdsWithPhoto, 'vehicle'))
        }
    } catch (e) {
        yield call(notifyUser, e)
    } finally {
        yield put(setIsFetchingMoreTasksDone())
        yield put(setIsFetchingVehicleTaskHistory(false))
    }
}

function* getTaskHistoryNextPage() {
    const endOfPagination: ReturnType<typeof selectVehicleTaskHistoryEndOfPagination> = yield select(
        selectVehicleTaskHistoryEndOfPagination,
    )
    if (!endOfPagination) {
        yield put(incrementTaskHistoryPagination())
    }
}

type UpdateVehicleRes = SagaReturnType<typeof updateVehicle>

export function* update(action: GetVehicleAction<typeof UPDATE_VEHICLE_INFO>) {
    try {
        const { vehicleId, data } = action.payload
        const res: UpdateVehicleRes = yield call(updateVehicle, vehicleId, data)
        if (res instanceof Error) {
            throw res
        }

        yield put(setCurrentVehicle(res))
        yield call(notifyUser, 'Updated vehicle', 'success')
    } catch (e) {
        yield call(notifyUser, e)
    }
}

type RemoveFromRepairRes = SagaReturnType<typeof removeFromRepairRequest>

export function* removeFromRepairs(action: GetVehicleAction<typeof REMOVE_VEHICLE_FROM_REPAIR>) {
    try {
        const { scooter } = action.payload
        const res: RemoveFromRepairRes = yield call(removeFromRepairRequest, [scooter.id])
        if (res instanceof Error) {
            throw res
        }

        if (!res.data.errors) {
            yield put(getVehicleAction(scooter.short))
            yield call(notifyUser, 'Successfully removed scooter from repairs', 'success')
        } else {
            yield call(notifyUser, { message: 'Failed to remove scooter from repairs' }, 'error')
        }
    } catch (e) {
        yield call(notifyUser, e)
    }
}

type BikeUnarmLockRes = SagaReturnType<typeof bikeUnarmLockRequest>

export function* bikeUnarmLock(action: GetVehicleAction<typeof BIKE_UNARM_LOCK>) {
    const bikeId = action.payload
    const res: BikeUnarmLockRes = yield call(bikeUnarmLockRequest, bikeId)

    if (res instanceof Error) {
        yield call(notifyUser, res)
    } else {
        yield call(notifyUser, 'Unarmed bike lock', 'success')
    }
}

export function* decommissionVehicleSaga(action: GetVehicleAction<typeof DECOMMISSION_VEHICLE>) {
    try {
        const { vehicleId, reason } = action.payload
        const decommissionVehiclesResults: DecommissionVehicleResult[] = yield call(
            decommissionVehicles,
            [vehicleId],
            reason,
        )

        if (decommissionVehiclesResults instanceof Error) {
            yield call(notifyUser, { message: 'Decommission failed' }, 'error')
            return
        }

        if (decommissionVehiclesResults[0].isSuccessful) {
            yield put(getVehicleAction(vehicleId))
            yield call(notifyUser, 'Decommission succeeded', 'success')
        } else {
            yield call(notifyUser, { message: 'Decommission failed' }, 'error')
        }
    } catch (e) {
        yield call(notifyUser, e)
    }
}

export function* undoDecommissionVehicleSaga(action: GetVehicleAction<typeof UNDO_DECOMMISSION_VEHICLE>) {
    try {
        const { vehicleId } = action.payload
        const undoDecommissionVehiclesResults: UndoDecommissionVehicleResult[] = yield call(undoDecommissionVehicles, [
            vehicleId,
        ])

        if (undoDecommissionVehiclesResults instanceof Error) {
            yield call(notifyUser, { message: 'Undo decommission failed' }, 'error')
            return
        }

        if (undoDecommissionVehiclesResults[0].isSuccessful) {
            yield put(getVehicleAction(vehicleId))
            yield call(notifyUser, 'Undo decommission succeeded', 'success')
        } else {
            yield call(notifyUser, { message: 'Undo decommission failed' }, 'error')
        }
    } catch (e) {
        yield call(notifyUser, e)
    }
}

export default function* watcher() {
    yield takeEvery(GET_VEHICLE, getVehicleData)
    yield takeEvery(GET_VEHICLE_RIDE_HISTORY, fetchRideHistory)
    yield takeEvery(GET_VEHICLE_TASK_HISTORY, fetchTaskHistory)
    yield takeEvery(GET_VEHICLE_DECOMMISSIONED_INFO, getVehicleDecommissionedInfoSaga)
    yield takeEvery(INCREMENT_TASK_HISTORY_PAGINATION, fetchTaskHistory)
    yield takeEvery(GET_VEHICLE_TASK_HISTORY_NEXT_PAGE, getTaskHistoryNextPage)
    yield takeEvery(UPDATE_VEHICLE_INFO, update)
    yield takeEvery(REMOVE_VEHICLE_FROM_REPAIR, removeFromRepairs)
    yield takeEvery(BIKE_UNARM_LOCK, bikeUnarmLock)
    yield takeEvery(DECOMMISSION_VEHICLE, decommissionVehicleSaga)
    yield takeEvery(UNDO_DECOMMISSION_VEHICLE, undoDecommissionVehicleSaga)
}
