import { omit } from 'lodash-es'
import { type SagaIterator } from 'redux-saga'
import { takeEvery, call, put, all, select, type SagaReturnType } from 'redux-saga/effects'
import {
    getOrganizationActiveTasks,
    getActiveTasksForVehicle,
    deleteTask,
    getVehicle,
    closeTask,
    createNewTask,
    editTask as editTaskRequest,
    getTaskTypes,
} from 'src/api'
import {
    setActiveTasks,
    getActiveTasksForVehicleAction,
    getActiveTasksOrg,
    removeAllActiveTasksFromBulk,
    setTaskTypes,
} from 'src/redux/task'
import * as vehicleActions from 'src/redux/scooter/scooter.actions'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import {
    GET_ACTIVE_TASKS_FOR_VEHICLE,
    GET_ACTIVE_TASKS_ORG,
    DELETE_TASK_FOR_ORGANIZATION,
    DELETE_TASK_FOR_VEHICLE,
    CLOSE_TASK_FOR_VEHICLE,
    CLOSE_TASK_FOR_ORGANIZATION,
    BULK_DROP_ACTIVE_TASKS_ORG,
    CREATE_TASK_FOR_ORGANIZATION,
    CREATE_TASK_FOR_VEHICLE,
    EDIT_TASK_VEHICLE,
    EDIT_TASK_ORGANIZATION,
    GET_TASK_TYPES,
    type GetActiveTasksForVehicle,
    type GetActiveTasksOrg,
    type DeleteTaskForVehicle,
    type DeleteTaskForOrganization,
    type CloseTaskForOrganization,
    type CloseTaskForVehicle,
    type BulkDropActiveTasksOrg,
    type CreateTaskForVehicle,
    type CreateTaskForOrganization,
    type EditTaskVehicle,
    type EditTaskOrganization,
    type GetTaskTypes,
} from 'src/redux/task/task.types'
import { selectWarehouses } from 'src/redux/warehouse'
import { type Warehouse } from 'src/api/fm/warehouse/warehouse.model'
import { type TaskFormProps } from 'src/components/parts/active-tasks/createTaskForm/task.types'

type GetActiveTasksForVehicleRes = SagaReturnType<typeof getActiveTasksForVehicle>

function* getActiveTasksForVehicleSaga(action: GetActiveTasksForVehicle) {
    try {
        const { vehicleId } = action.payload
        const tasks: GetActiveTasksForVehicleRes = yield call(getActiveTasksForVehicle, vehicleId)

        if (tasks instanceof Error) {
            throw tasks
        }

        yield put(setActiveTasks(tasks))
    } catch (e) {
        console.log('Failed to get active tasks for scooter', e)
    }
}

type GetOrganizationActiveTasks = SagaReturnType<typeof getOrganizationActiveTasks>

function* getTasksOrg(action: GetActiveTasksOrg) {
    try {
        const { id, email } = action.payload
        const tasks: GetOrganizationActiveTasks = yield call(getOrganizationActiveTasks, id, email)

        if (tasks instanceof Error) {
            throw tasks
        }

        yield put(setActiveTasks(tasks.data.data))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type DeleteTaskRes = SagaReturnType<typeof deleteTask>

export function* deleteTaskForVehicle(action: DeleteTaskForVehicle) {
    try {
        const task = action.payload
        const deleteRes: DeleteTaskRes = yield call(deleteTask, task.id)
        if (deleteRes instanceof Error) {
            throw deleteRes
        }
        // Get updated vehicle and task data
        yield put(vehicleActions.getVehicle(task.vehicleId))
        yield put(getActiveTasksForVehicleAction(task.vehicleId))
    } catch (e) {
        yield call(notifyUser, e)
    }
}

function* deleteTaskForOrganization(action: DeleteTaskForOrganization) {
    try {
        const task = action.payload
        const deleteRes: DeleteTaskRes = yield call(deleteTask, task.id)
        if (deleteRes instanceof Error) {
            throw deleteRes
        }
        yield put(getActiveTasksOrg(task.accountId))
    } catch (e) {
        yield call(notifyUser, e)
    }
}

type CloseTask = SagaReturnType<typeof closeTask>

export function* closeTaskForOrganizationSaga(action: CloseTaskForOrganization): SagaIterator {
    try {
        const { taskId, status, accountId, warehouseId } = action.payload
        const res: CloseTask = yield call(closeTask, taskId, status, warehouseId)
        if (res instanceof Error) {
            throw res
        }

        // Show notification for transport tasks with information about at which warehouse the vehicle was dropped at
        if (warehouseId) {
            const warehouses: Warehouse[] = yield select(selectWarehouses)
            const warehouse = warehouses.find(w => w.id === warehouseId)
            const warehouseName = warehouse?.name ?? 'warehouse'

            yield call(notifyUser, `Vehicle successfully dropped at ${warehouseName}`, 'success')
        } else {
            yield call(notifyUser, `Successfully ${status} task`, 'success')
        }

        yield put(getActiveTasksOrg(accountId))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

export function* closeTaskForVehicleSaga(action: CloseTaskForVehicle): SagaIterator {
    try {
        const { vehicleId, taskId, status, warehouseId } = action.payload
        const res: CloseTask = yield call(closeTask, taskId, status, warehouseId)
        if (res instanceof Error) {
            throw res
        }

        // Show notification for transport tasks with information about at which warehouse the vehicle was dropped at
        if (warehouseId) {
            const warehouses: Warehouse[] = yield select(selectWarehouses)
            const warehouse = warehouses.find(w => w.id === warehouseId)
            const warehouseName = warehouse?.name ?? 'warehouse'

            yield call(notifyUser, `Vehicle successfully dropped at ${warehouseName}`, 'success')
        }

        // Update vehicle page
        yield put(getActiveTasksForVehicleAction(vehicleId))
        yield put(vehicleActions.getVehicle(vehicleId))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

export function* bulkDropOrg(action: BulkDropActiveTasksOrg): SagaIterator {
    try {
        const { accountId, tasks, status, warehouseId } = action.payload
        const res: CloseTask = yield all(
            tasks.map(task => {
                return call(closeTask, task.id, status, task.type === 'transport' ? warehouseId : undefined)
            }),
        )
        if (res instanceof Error) {
            throw res
        }

        // Show notification for transport tasks with information about at which warehouse the vehicles were dropped at
        if (warehouseId) {
            const warehouses: Warehouse[] = yield select(selectWarehouses)
            const warehouse = warehouses.find(w => w.id === warehouseId)
            const warehouseName = warehouse?.name ?? 'warehouse'

            yield call(notifyUser, `Vehicles successfully dropped at ${warehouseName}`, 'success')
        }

        yield put(removeAllActiveTasksFromBulk())
        yield put(getActiveTasksOrg(accountId))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

function* createTaskForVehicle(action: CreateTaskForVehicle) {
    try {
        const { task, vehicleId } = action.payload
        const res: ReturnType<typeof createTask> = yield createTask(task)
        if (res instanceof Error) {
            throw res
        }
        yield call(notifyUser, 'Task successfully added to vehicle', 'success')

        yield put(getActiveTasksForVehicleAction(vehicleId))

        // Update scooter page
        yield put(vehicleActions.getVehicle(vehicleId))
    } catch (e) {
        yield call(notifyUser, e)
    }
}

function* createTaskForOrganization(action: CreateTaskForOrganization) {
    try {
        const { task, organizationId } = action.payload
        const res: ReturnType<typeof createTask> = yield createTask(task)
        if (res instanceof Error) {
            throw res
        }
        yield call(notifyUser, 'Task successfully added to the organization', 'success')
        yield put(getActiveTasksOrg(organizationId))
    } catch (e) {
        yield call(notifyUser, e)
    }
}

type CreateTaskRes = SagaReturnType<typeof createNewTask>
type GetVehicle = SagaReturnType<typeof getVehicle>

function* createTask(task: TaskFormProps) {
    const vehicle: GetVehicle = yield call(getVehicle, task.vehicleShort!)

    if (vehicle instanceof Error) {
        throw new Error('Invalid scooter short')
    }

    const newTask: CreateTaskRes = yield call(createNewTask, {
        ...omit(task, 'vehicleShort'),
        vehicleId: vehicle.id,
    })
    return newTask
}

type EditTaskRes = SagaReturnType<typeof editTaskRequest>

export function* editTaskVehicle(action: EditTaskVehicle) {
    const { task, vehicleId } = action.payload

    const taskRes: EditTaskRes = yield call(editTaskRequest, task)
    if (taskRes instanceof Error) {
        yield call(notifyUser, taskRes, 'error')
        return
    }

    yield put(vehicleActions.getVehicle(vehicleId))
    yield put(getActiveTasksForVehicleAction(vehicleId))

    yield call(notifyUser, 'Successfully updated task', 'success')
}

export function* editTaskOrganization(action: EditTaskOrganization) {
    const { task, organizationId } = action.payload

    const taskRes: EditTaskRes = yield call(editTaskRequest, task)
    if (taskRes instanceof Error) {
        yield call(notifyUser, taskRes, 'error')
        return
    }

    yield put(getActiveTasksOrg(organizationId))
    yield call(notifyUser, 'Successfully updated task', 'success')
}

type GetTaskTypesRes = SagaReturnType<typeof getTaskTypes>

export function* getTaskTypesSaga(_: GetTaskTypes) {
    const res: GetTaskTypesRes = yield call(getTaskTypes)

    if (res instanceof Error) {
        yield call(notifyUser, res, 'error')
        return
    }

    yield put(setTaskTypes(res))
}

export default function* watcher() {
    yield takeEvery(GET_ACTIVE_TASKS_FOR_VEHICLE, getActiveTasksForVehicleSaga)
    yield takeEvery(GET_ACTIVE_TASKS_ORG, getTasksOrg)
    yield takeEvery(DELETE_TASK_FOR_ORGANIZATION, deleteTaskForOrganization)
    yield takeEvery(DELETE_TASK_FOR_VEHICLE, deleteTaskForVehicle)
    yield takeEvery(CLOSE_TASK_FOR_VEHICLE, closeTaskForVehicleSaga)
    yield takeEvery(CLOSE_TASK_FOR_ORGANIZATION, closeTaskForOrganizationSaga)
    yield takeEvery(BULK_DROP_ACTIVE_TASKS_ORG, bulkDropOrg)
    yield takeEvery(CREATE_TASK_FOR_VEHICLE, createTaskForVehicle)
    yield takeEvery(CREATE_TASK_FOR_ORGANIZATION, createTaskForOrganization)
    yield takeEvery(EDIT_TASK_VEHICLE, editTaskVehicle)
    yield takeEvery(EDIT_TASK_ORGANIZATION, editTaskOrganization)
    yield takeEvery(GET_TASK_TYPES, getTaskTypesSaga)
}
